<template>
    <div class="searcher custom-shadow">
        <itc-card color="#343a40">
            <div slot="header" class="text-center text-white">
                <h4>Buscador</h4>
            </div>
            <div slot="body" class="row">
                <div class="col-4">
                    <div class="SearchByDate">
                        <label>Por fecha</label>
                        <input
                            ref="dateSearcher"
                            v-model="dateSearcher" 
                            @change="searchFunction(dateSearcher, 'date')"
                            class="form-control" type="date">
                    </div>
                </div>
            </div>
        </itc-card>
    </div>
</template>

<script>
import Card from '@/components/mix/Card'

export default {
    components: {
        'itc-card': Card
    },
    data(){
        return {
            dateSearcher: null,
        }
    },
    props: {
        searchFunction: Function
    },
    created(){
        this.$nextTick(() => {
            this.$refs.dateSearcher.focus()
        })
    }
}
</script>

<style lang="scss" scoped>
.custom-border{
    border-bottom: 1px solid lightgray;
}