<template>
    <div class="orders">
        <h4 class="pt-3">Cajas diarias</h4>
        <hr>
        <itc-cr-searcher :searchFunction="searchCashRegister" class="my-4"></itc-cr-searcher>
        <transition name="fade" mode="out-in">
            <keep-alive>
                <component
                    :is="actualComponent"
                    :cashRegisters="cashRegisters"
                    :cashRegister="cashRegister"
                    :selectCashRegister="selectCashRegister"
                    @click-on-back-button="actualComponent = 'itc-cr-table'">
                </component>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import CashRegisterSearcher from '@/components/admin/cash_register/CashRegisterSearcher'
import CashRegisterTable from '@/components/admin/cash_register/CashRegisterTable'
import CashRegisterControlPanel from '@/components/admin/cash_register/CashRegisterControlPanel'

export default {
    data(){
        return {
            cashRegisters: [],
            cashRegister: {},
            actualComponent: 'itc-cr-table',
        }
    },
    methods: {
        async searchCashRegister(searcher, type){
            const resp = await axios.get(`/cash-register/${type}?searcher=${searcher}`)
            this.actualComponent = 'itc-cr-table'
            this.cashRegisters = resp.data
        },
        selectCashRegister(index){
            this.cashRegister = this.cashRegisters[index]
            this.actualComponent = 'itc-cr-panel'
        }
    },
    components: {
        'itc-cr-table': CashRegisterTable,
        'itc-cr-searcher': CashRegisterSearcher,
        'itc-cr-panel': CashRegisterControlPanel
    },
    async created(){
        const resp = await axios.get('/cash-register/last');
        this.cashRegisters = resp.data
    }   
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>