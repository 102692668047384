<template>
    <itc-card color="#343a40" class="custom-shadow">
        <div slot="header" class="text-white">
            <div class="row">
                <div class="col-3">
                    <button 
                        @click="$emit('click-on-back-button')"
                        class="btn btn-info"> Volver
                    </button>
                </div>
                <div class="text-center col">
                    <h3>Caja diaria</h3>
                </div>
                <div class="col-3">
                    <button @click="printCashRegister" class="btn btn-success float-right mx-2" >Imprimir</button>
                </div>
            </div>
        </div>
        <div slot="body">
            <div class="row">
                <div class="col-8">
                    <div class="p-3">
                        <h4>Datos de la caja</h4>
                        <hr>
                        <ul>
                            <li><strong>Estado: </strong>{{cashRegister.isClosed | filterStatus}}</li>
                            <li><strong>Fecha de inicio: </strong>{{cashRegister.initialDate}}</li>
                            <li><strong>Fecha de finalización: </strong>{{cashRegister.finishDate || 'Aún no finalizo'}}</li>
                        </ul>
                        <div v-if="cashRegister.observs">
                            <h4>Observaciones</h4>
                            <hr>
                            <p>{{cashRegister.observs}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <itc-card  color="#fff" class="custom-shadow px-2">
                        <div slot="header">
                            <h4>Montos
                                <i class="small-text fas fa-square text-warning mx-2"></i><small class="small-text">No coinciden</small>
                            </h4>
                        </div>
                        <div slot="body">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>Método</th>
                                        <th>Monto</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><small>Inicial</small></td>
                                        <td><small>${{cashRegister.initialAmount}}</small></td>
                                        <td><small>-</small></td>
                                    </tr>
                                    <tr 
                                        :class="{'warning-background': !amount.matches}"
                                        v-for="(amount, key) in cashRegister.amounts" :key="key">
                                        <td><small>{{key | filterPaymentMethodName}}</small></td>
                                        <td><small>${{amount.amount}}</small></td>
                                        <td><small>{{amount.count}}</small></td>
                                    </tr>
                                    <tr>
                                        <td><small>Gastos<i class="fas fa-eye mx-2 set-pointer text-info"></i></small></td>
                                        <td><small>${{cashRegister.expenses.totalExpenses}}</small></td>
                                        <td><small>{{cashRegister.expenses.expenses.length}}</small></td>
                                    </tr>
                                    <tr class="text-white" style="background-color:#343a40">
                                        <td></td>
                                        <td>${{cashRegister.totalAmount}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </itc-card>
                </div>
            </div>
        </div>
    </itc-card>
</template>

<script>
import axios from 'axios'
import Card from '@/components/mix/Card'

import * as utils from '@/assets/utils/utils.js'

export default {
    props: {
        cashRegister: Object,
    },
    components: {
        'itc-card': Card
    },
    methods: {
        async printCashRegister(){
            const resp = await axios.get(`/cash-register/print-definition?searcher=${this.cashRegister._id}`)
            const newWindow = window.open({});
            newWindow.document.write(resp.data)
            setTimeout(() => {
                newWindow.print()
                newWindow.close()
            }, 2000)
        }
    },
    filters: {
        filterStatus(status){
            return utils.filterCashRegisterStatus(status)
        },
        filterPaymentMethodName(name){
            return utils.filterPaymentMethodName(name)
        }
    }
}
</script>

<style lang="scss" scoped>
.small-text{
    font-size: 0.5em;
}
.warning-background {
    background-color: #ffc107;
}
li{
    list-style: none;
}
th {
  text-align: left;
}

th, td {
    border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.2);
    padding: 6px;
}
</style>
