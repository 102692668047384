<template>
    <div class="products-list custom-shadow">
        <table id="tablePreview" class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Estado</th>
                    <th>Monto</th>
                    <th>Fecha inicio</th>
                    <th>Fecha finalización</th>
                </tr>
            </thead>
            <tbody>
                <tr  v-if="cashRegisters.length == 0">
                    <td colspan="4" class="text-center">
                        <h1>Sin cajas</h1>
                    </td>
                </tr>
                <tr
                    v-for="(cashRegister, i) in cashRegisters" 
                    :key="i" 
                    class="product-item"
                    @click="selectCashRegister(i)">
                    <th scope="row" :class="{'text-danger': cashRegister.hasErrors}">{{cashRegister.isClosed | filterStatus}}</th>
                    <td>${{cashRegister.totalAmount}}</td>
                    <td>{{cashRegister.initialDate}}</td>
                    <td>{{cashRegister.finishDate || 'Aún no finalizo'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios'
import * as utils from '@/assets/utils/utils.js'

export default {
    props: {
        cashRegisters: Array,
        selectCashRegister: Function
    },
    filters: {
        filterStatus(status){
            return utils.filterCashRegisterStatus(status)
        }
    },
}
</script>

<style lang="scss" scoped>
.product-item:hover{
    background-color: lightgreen;
}
</style>